import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/mutual_link.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/footer.css'
import React from 'react'
import { Header } from '../components/Header'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import Seo from '../components/Seo'
import { url } from '../config/domain'

export default function MutualLink({ location }: any): JSX.Element {
  const locatePath = location.pathname.split('/')

  return (
    <div>
      <div className="wrapper">
        <Seo
          title={'【リンク集】おすすめブログ・サイト一覧'}
          description={
            'KUROROがおすすめする、【リンク集】ブログ・サイト一覧ページです。KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          url={url + 'mutual-link/'}
          noIndex={false}
          contentType="article"
        />

        <Header location={locatePath} />

        <h1 className="mutualLink__h1">【リンク集】おすすめブログ・サイト一覧</h1>

        <section>
          <h2 className="mutualLink__h2">その他 : お気に入りブログ・サイト</h2>
          <ul>
            <li>
              <a href="https://espo-game.jp/" target="_blank" rel="noopener noreferrer">
                eスポ
              </a>
              : Youtuberやストリーマー、プロゲーマーなどの情報を中心とした
              <strong>ゲーマーのためになる</strong>情報を発信するメディアです。
            </li>
            <li>
              <a href="https://tele-labo.jp/" target="_blank" rel="noopener noreferrer">
                テレラボ
              </a>
              : <strong>リモートワーカーのための</strong>求人メディアです。
            </li>
            <li>
              <a href="https://fitmap.jp/" target="_blank" rel="noopener noreferrer">
                FitMap(フィットマップ)
              </a>
              : <strong>「あなたにピッタリのジムを」</strong>
              というコンセプトの元、ジムユーザー様のジム探しのお役に立つサイトです。
            </li>
            <li>
              <a href="https://money-career.com/" target="_blank" rel="noopener noreferrer">
                マネーキャリア
              </a>
              : 保険、資産運用、お金の知識、貯金、投資など
              <strong>「お金」に不安や疑問を抱えている人に向けた</strong>メディアです。
            </li>
          </ul>
        </section>

        <Breadcrumb
          breadcrumb={[
            {
              link: '/',
              text: 'KURORO BLOG',
            },
            {
              link: '/mutual-link/',
              text: 'おすすめリンク集',
            },
          ]}
        />

        <Footer />
      </div>
    </div>
  )
}
